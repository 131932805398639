<template>
  <div class="display-reports-panel">
    <b-tabs>
      <b-tab class="gallery-tab">
        <template #title>
          <p class="tab-title">Gallery</p>
        </template>
        <div class="gallery-content">
          <div class="gallery">
            <div v-for="report in reports" :key="report.id" :class="['report-card mt-0 mr-4']">
              <div 
                :class="['pdf-icon', selectedResource == report ? 'selected-report' : '']" 
                @click="chooseReport(report)">
                <b-icon icon="file-earmark-pdf" ></b-icon>
              </div>
              <p class="filename-label">{{ trimReportName(report.filename) }}</p>
            </div>
          </div>
        </div>
        <div class="resource-section">
        <template v-if="selectedResource!=null">
          <p class="resource-title">{{ resourceTitle }}</p>
        </template>
        <div id="display-pdf"></div>
        <template v-if="selectedResource!=null">
          <p class="resource-info">File URL: <a :href="resourceURL" target="_blank">Open link</a> </p>
          <p class="resource-info">Extension: {{ resourceExtension }} </p>
          <p class="resource-info">Properties: {{ resourceProperties }} </p>
        </template>
      </div>
      </b-tab>
      <b-tab>
        <template #title>
          <p class="tab-title">Upload</p>
        </template>
        <div class="upload-report">
          <FormulateInput
            accept=".pdf"
            label="Drop file to upload or click below"
            label-class='form-label'
            error-class='bigger-error'
            type="file"
            id="uploadedResource"
            name="uploadedResource"
            help="Select a PDF file"
            v-model='uploadedResource'
            validation="validateFilePdfType"
            :validation-rules="{ validateFilePdfType }"
            :validation-messages="{
              validateFilePdfType: 'Invalid file type. PDF required'
            }"
            @file-upload-complete="uploadDone=true"
            @file-removed="uploadDone=false"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PDFObject from 'pdfobject';
import { mapGetters } from 'vuex';

export default {
  name: 'ReportViewCreationPanel',
  data() {
    return {
      uploadedResource: null,
      selectedResource: null,
      maxFileSize: 10485760,
      uploadDone: false,
      resourceTitle: '',
      resourceURL: '',
      resourceExtension: '',
      resourceProperties: 'Report'
    };
  },
  mounted() {
    this.selectImageForCurrentInsight();
  },
  computed: {
    reports() {
      return this.studies?.flatMap(study => study.reports);
    },
    ...mapGetters('project', ['studies','currentInsight','currentInsights', 'editInsightMode'])
  },
  methods: {
    selectImageForCurrentInsight() {
      if (this.editInsightMode && this.currentInsight) {
        if (this.currentInsight.reportview_set.length > 0) {
          if (this.currentInsight.reportview_set[0].report) {
            let selectedReport = this.reports.find(report => report.id == this.currentInsight.reportview_set[0].report.id);
            this.chooseReport(selectedReport);
          }
        }
      }
    },
    async chooseReport(report) {
      this.$store.dispatch('project/setCurrentInsightResource', report);
      
      this.selectedResource = report;
      let extensionIndex = report.filename.lastIndexOf('.');
      this.resourceTitle = report.filename.slice(0, extensionIndex);
      this.resourceURL = report.report_file;
      this.resourceExtension = report.filename.slice(extensionIndex).toLowerCase();

      this.embedPdf(report.report_file, 'display-pdf');
    },
    validateFilePdfType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          let allowedTypes = ['pdf'];
          let fileExtension = file.name.match(/\.(.+)$/);
          if (!allowedTypes.includes(fileExtension[1].toLowerCase())) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    },
    embedPdf(file, id) {
      if (!file) {
        return;
      }
      let options = { pdfOpenParams: { view: 'FitH'}};
      PDFObject.embed(file, `#${id}`, options);
    },
    trimReportName(name) {
      if(name.length > 12) {
        return `${name.substring(0, 12)}...`;
      } else {
        return name;
      }
    }
  },
  watch: {
    uploadDone(newValue) {
      if (newValue==true) {
        this.$store.dispatch('project/setCurrentInsightResource', this.uploadedResource);
      } else {
        this.$store.dispatch('project/setCurrentInsightResource', null);
      }
    },
    currentInsight(newValue) {
      if (newValue) {
        this.selectImageForCurrentInsight();
      }
    }
  }
};
</script>

<style>

.display-reports-panel .tab-content {
  border-left: 1px solid #E5E7EA !important;
  border-bottom: 1px solid #E5E7EA !important;
  border-right: 1px solid #E5E7EA !important;
}
.display-reports-panel .tab-content, .display-reports-panel .tab-pane {
  margin-top: 0rem !important;
  background-color: #F6F7F9;
  padding-left: 0.4rem;
}

.display-reports-panel .nav-item > .nav-link {
  opacity: 0.7 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
  border-color: #E5E7EA;
}
.display-reports-panel .nav-item > .nav-link:hover {
  opacity: 1 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
}
.display-reports-panel .nav-item > .nav-link.active {
  border-bottom-color: #F6F7F9 !important;
  opacity: 1 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
}
.bigger-error {
  font-size: larger;
  color: #960505;
}
</style>
<style scoped>
.selected-report {
  border: 2px rgb(77, 187, 255) solid !important;
}

.display-reports-panel {
  display: flex;
  flex-wrap: wrap;
}

.gallery-tab {
  display: flex;
}

.gallery-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  height: 15rem;
  overflow-x: auto;
  width: 39.7rem;
  background-color: #F6F7F9;
  margin-top: 0 !important;
  padding-top: 1rem !important;
}

/* scrollbar customization */
::-webkit-scrollbar {
  width: 0.3rem;
}
::-webkit-scrollbar-track {
  background: #E5E7EA;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.tab-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #596066;
  text-decoration: none;
}

.report-card {
  /* flex: 0 0 calc(100% / 6); */
  padding: 0.5rem;
  width: 5rem;
}

.report-card .pdf-icon {
  font-size: 5rem;
}

.upload-report {
  height: 15.5rem;
  overflow-x: auto;
  width: 36.7rem;
  margin-top: 0 !important;
  padding: 2rem 13rem 0 13rem !important;
  text-align: center;
}

.resource-section {
  width: 21.65rem;
  background-color: #F6F7F9;
  padding: 1.5rem 0 1rem 1rem;
  margin-top: 0;
  margin-left: 0.5rem;
  border-left: 1px solid #E5E7EA !important;
  overflow-x: auto;
  max-height: 16.5rem;
}

.resource-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #596066;
  word-wrap: break-word;
}

.resource-info {
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #596066;
  margin-top: 0.7rem;
}

.resource-info a {
  text-decoration: none;
  color: #596066;
}

.resource-info a:hover {
  color: #E5E7EA;
}

.resource-section #display-pdf {
  border-radius: 0.7rem;
  width: 15.3rem;
  height: 9rem;
  margin: 0;
  margin-top: 1rem;
}

.filename-label {
  font-size: 0.75rem;
}

@media screen and (max-width: 80rem) {
  .gallery-content, .gallery {
    width: 25.5rem !important;
    height: 11.5rem !important;
  }

  .resource-section {
    height: 11rem !important;
    width: 19.8rem !important;
  }

  .upload-report {
    width: 20.9rem !important;
    height: 11.5rem !important;
  }
}

</style>